@import "styles/constants.less";

.test {
  position: absolute;
}

#meytvassa-h1 {
  background-color: @black;
}
.paragraph-ornament {
  width: 20vw;
  height: 20vw;
  overflow: hidden;
  border-radius: 50%;
}
.paragraph-asset {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  width: 100vw;
  height: 100vh;
}
