@import "styles/constants.less";
.image-grower-box {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo {
  width: 344px;
  filter: opacity(1);
  transition: 1s all;
  &.destroy {
    filter: opacity(0);
  }
}
.down,
.up {
  position: absolute;
  height: 50%;
  width: 100%;
  box-sizing: border-box;
}
.up {
  top: 0;
  left: 0;
}
.down {
  bottom: 0;
  right: 0;
  transform: rotate(180deg);
}
.rotating(@transform, @initialDeg, @duration) {
  @keyframes loader {
    from {
      transform: @transform rotate(@initialDeg);
    }
    to {
      transform: @transform rotate(calc(@initialDeg - 360deg));
    }
  }

  animation: loader @duration linear infinite;
}

.destructured-imagebox {
  position: absolute;

  width: 1145px;
  min-width: 1145px;
  min-height: 1327px;
  height: 1327px;
  transform: scale(0.3);
  transition: all 0.8s cubic-bezier(1, -0.03, 0.58, 1);

  &.shadow {
    transform: scale(0.3) translate(0, 0);
    filter: blur(20px) brightness(0) contrast(0.8) grayscale(1);
    &.destroy {
      transform: scale(0.22) rotate(95deg);
      filter: blur(20px) brightness(0) contrast(0.8) grayscale(1);
      &.loading {
        .rotating(scale(0.22), 95deg, 10s);
      }
    }
  }
  &.main {
    &.destroy {
      transform: scale(0.3) rotate(-80deg);
      filter: blur(6px) brightness(0.5);
      &.loading {
        .rotating(scale(0.3), -30deg, 20s);
      }
    }
  }
  &.second {
    &.destroy {
      transform: scale(0.5) rotate(-30deg);
      filter: brightness(1) blur(6px);
      &.loading {
        .rotating(scale(0.5), -40deg, 30s);
      }
    }
  }
  &.third {
    &.destroy {
      transform: scale(0.8) rotate(60deg);
      filter: brightness(0.5) blur(2px);
      img {
        filter: drop-shadow(0 0 20px rgba(255, 255, 255, 0.1))
          drop-shadow(0 0 40px rgba(255, 0, 255, 0.2))
          drop-shadow(0 0 60px rgba(0, 255, 255, 0.15));
      }
      &.loading {
        .rotating(scale(0.8) , 60deg, 40s);
      }
    }
  }
  &.fourth {
    filter: brightness(4.5);
    &.destroy {
      transform: scale(1.2) rotate(-180deg);
      filter: brightness(5);
      &:not(.shadow) img {
        filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.626))
          drop-shadow(0 0 20px rgba(255, 0, 255, 0.451))
          drop-shadow(0 0 30px rgba(0, 255, 255, 0.15));
      }
      &.loading {
        .rotating(scale(1.2) , -180deg, 10s);
      }
    }
    &.shadow {
      filter: blur(2px) brightness(0.3);
      &.destroy {
        filter: blur(2px) brightness(0.3);
        transform: scale(1) rotate(-150deg);
      }
    }
  }
}

.brick {
  position: absolute;
  transition: all 1s ease-in-out; // cubic-bezier(0.45, 0.05, 0.55, 0.95);
}

.initial,
.z {
  &.shape {
    transform: translate(0%, 0%) rotate(0deg) rotateX(0);
    .piece-1 {
      transform: translate(0%, 0%) rotate(0deg) rotateX(0);
    }
    .piece-2 {
      transform: translate(0%, 0%) rotate(0deg) rotateX(0);
    }
    .piece-3 {
      transform: translate(0%, 0%) rotate(0deg) rotateX(0);
    }
    .piece-4 {
      transform: translate(77%, 18.5%) rotate(0deg) rotateX(0);
    }
  }
  &.tic {
    transform: translate(70%, 100%) rotate(0deg) rotateX(0deg);
  }
  &.center {
    transform: translate(82%, 38%) rotate(0deg) rotateX(0);
  }
  &.side {
    transform: translate(329%, 17%) rotate(0deg) rotateX(0deg);
  }
}
//
//
// DESTROYED
//
//
.destroy {
  &.shape {
    .piece-1 {
      transform: translate(-98%, -12%) rotate(29deg)
        rotateX(calc(-360deg + 45deg));
    }
    .piece-2 {
      // transform: translate(-11%, 22%) rotate(6deg)
      // rotateX(calc(-360deg + 45deg));
      transform: translate(-24%, -30%) rotate(41deg)
        rotateX(calc(-360deg + 45deg));
    }
    .piece-3 {
      // transform: translate(7%, -62%) rotate(27deg) rotateX(calc(360deg + 45deg));
      transform: translate(35%, -73%) rotate(calc(360deg + 74deg))
        rotateX(calc(360deg + 45deg));
    }
    .piece-4 {
      transform: translate(97%, -20%) rotate(102deg)
        rotateX(calc(360deg + 45deg));
    }
  }
  &.tic {
    transform: translate(-44%, 200%) rotate(33deg) rotateX(calc(360deg + 45deg));
  }
  &.center {
    transform: translate(-58%, 113%) rotate(25deg) rotateX(calc(360deg + 45deg));
  }
  &.side {
    transform: translate(411%, -20%) rotate(0deg) rotateX(calc(360deg + 45deg));
  }
}
