.previous {
  display: flex;
  align-self: flex-start;
  color: white;
  cursor: pointer;
  span {
    z-index: 1;
    padding: 5px;
    font-weight: 800;
  }
}
