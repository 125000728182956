@color0: #7c9296;
@color1: #d0eb96;
@color2: #ebbc96;
@color3: #95deea;
@color4: #d496eb;

@color5: #7ceb8a;
@color6: #ebcd7c;
@color7: #7c96eb;
@color8: #eb7c9b;
@color9: #60a4eb;

@primary: @color9;
@secondary: @color5;
@purple: @color7;
@orange: @color2;
@green: @color5;
@blue: @color9;
@red: @color8;
@yellow: @color1;
@pink: @color4;
@bluesky: #93b6c0;
@whitesky: #bad8dc;
@brown: #5e605c;
// ANTD
@primary-color: @primary;
// @black: #353d55;
@black: #010719;
@emerald: #0ae448;
@emerald-blue: #0085d0;

// Font size
@xxl-font-size: 15em;
@xl-font-size: 10em;
@l-font-size: 5em;
@medium-font-size: 3em;
@default-font-size: 2em;
@small-font-size: 1.5em;
@gradient_purple: linear-gradient(166.9deg, @pink 53.19%, @purple 107.69%);
@gradient_emerald: linear-gradient(
  166.9deg,
  @emerald 53.19%,
  @emerald-blue 107.69%
);
.white {
  color: white;
}
.black {
  color: @black;
}
.outlined {
  text-shadow: -1px -1px 0 @black,
    /* Ombre en haut à gauche */ 1px -1px 0 @black,
    /* Ombre en haut à droite */ -1px 1px 0 @black,
    /* Ombre en bas à gauche */ 1px 1px 0 @black; /* Ombre en bas à droite */
  letter-spacing: 2px;
  font-weight: bold; /* Optionnel : rend le texte plus épais */
}
@gradient_sky: linear-gradient(166.9deg, @bluesky 53.19%, @brown 107.69%);
.glassmorphism-property {
  color: white;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.background-text {
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.gradient-purple-text {
  background: @gradient_purple;
  .background-text;
}
.gradient-emerald-text {
  background: @gradient_emerald;
  .background-text;
}
// FONTSIZE CONSTANT
.font-transition {
  transition: font-size 1s;
  line-height: 1.2em;
  margin: 0;
}
.xxl-font-size {
  font-size: @xxl-font-size;
  .font-transition;
}
.xl-font-size {
  font-size: @xl-font-size;
  .font-transition;
}
.l-font-size {
  font-size: @l-font-size;
  .font-transition;
}
.medium-font-size {
  font-size: @medium-font-size;
  .font-transition;
}
.default-font-size {
  font-size: @default-font-size;
  .font-transition;
}

@media screen and (max-width: 1023px) {
  .xxl-font-size {
    font-size: @xl-font-size;
  }
}
@media screen and (max-width: 700px) {
  .default-font-size {
    font-size: @medium-font-size;
  }
}
@media screen and (max-width: 100px) {
  .xxl-font-size {
    font-size: @default-font-size;
  }
  .default-font-size {
    font-size: @default-font-size;
  }
}
