@import "styles/constants.less";

#button {
  //   width: 30px;
  //   height: 30px;
  transition-delay: 1s;
}
.menu-wrapper {
  // border: 1px solid white;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  padding: 2px 5px 2px 10px;
  .text {
    place-self: center;
  }
}
.icon {
  padding: 5px;
  background: transparent;
  //   border: 1px solid @pink;
  border-radius: 50%;
  width: 31px;
  height: 31px;
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  gap: 5px;

  transition: 0.4s;
  cursor: pointer;

  .vector {
    display: block;
    width: 100%;
    height: 4px;
    background-color: white;
    transition: 0.4s;

    &:nth-child(1) {
      background-color: @blue;
    }
    &:nth-child(2) {
      background-color: @green;
    }

    &:nth-child(3) {
      background-color: @yellow;
    }
  }
  &.initializing {
    .vector {
      width: 0%;
    }
  }
  &.close:not(.initializing) {
    .vector:nth-child(1) {
      width: 100%;
      border-radius: 0 3px 0 0;
    }
    .vector:nth-child(2) {
      width: 80%;
    }

    .vector:nth-child(3) {
      border-radius: 0 0 3px 0;
      width: 60%;
    }
    // &::before {
    //   content: "";
    //   transition: all 0.2s;
    //   transition-delay: 0.4s, 0s;
    //   position: absolute;
    //   right: 0px;
    //   background-color: black;
    //   transform: rotate(180deg);
    // }
    // &:hover::before {
    //   content: "Menu";
    //   right: calc(100% + 5px);
    // }
  }
  &.open,
  &.close:hover {
    transform: rotate(180deg);
    .vector:nth-child(1) {
      width: 60%;
      transform: translate(-1px, 4px) rotate(-45deg);
      border-radius: 3px 0 0 3px;
    }
    .vector:nth-child(2) {
      width: 100%;
      z-index: 1;
      border-radius: 3px 0 0 3px;
    }

    .vector:nth-child(3) {
      width: 60%;
      border-radius: 3px 0 0 3px;
      transform: translate(-1px, -5px) rotate(45deg);
    }
  }

  &.open {
    transform: rotate(360deg);
    &:hover {
      transform: rotate(180deg);
      .vector:nth-child(1) {
        width: 100%;
        transform: translate(0, 5px) rotate(-45deg);
        border-radius: 3px;
      }
      .vector:nth-child(2) {
        width: 100%;
        z-index: 1;
        border-radius: 3px;
        transform: translate(0, -4px) rotate(45deg);
      }

      .vector:nth-child(3) {
        display: none;
        width: 0%;
      }
    }
  }
}
