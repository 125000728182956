@import "styles/constants.less";

.title-h1,
.title-h2 {
  &.url {
    .background-text;
  }
  color: @black;
  transition: all 1s;
  text-align: center;
  &:not(.shadow) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  &.shadow {
    color: transparent;
    text-shadow: 0 0 10px @black;
  }
  &.no-shadow {
    color: transparent;
    text-shadow: 0 0 10px transparent;
  }
}
h1,
h2,
.title-h1,
.title-h2 {
  // background: center;
  .l-font-size;
}
p {
  color: white;
  .default-font-size;
  border-bottom: 1px solid;
  padding: 1em;
  margin: 10px;
  width: 600px;
  max-width: 80vw;
  box-sizing: border-box;

  &::first-letter {
    font-family: "beckett";
    font-weight: 400;
    initial-letter: 3;
    margin-right: 5px;
  }
}

h1,
.title-h1 {
  .xxl-font-size;
  &.xl {
    .xl-font-size;
  }
  &.l {
    .l-font-size;
  }
}
.ui-button {
}

@media screen and (max-width: 1200px) {
  .title-h1,
  h1 {
    .xl-font-size;
    &.xl {
      .l-font-size;
    }
    &.l {
      .default-font-size;
    }
  }
}
@media screen and (max-width: 700px) {
  p {
    font-size: @small-font-size;
  }

  .title-h1,
  h1 {
    .medium-font-size;
    &.xl {
      .medium-font-size;
    }
    &.l {
      .default-font-size;
    }
  }
  h2 {
    .medium-font-size;
    &.xl {
      .medium-font-size;
    }
    &.l {
      .default-font-size;
    }
  }
}
