@import "./src/styles/constants.less";
.char-size {
  width: 2em !important;
  height: 1em !important;
  display: flex;
  justify-content: center;
  transition: all 0.1 ease;
  color: white;
}
.disabled {
  color: #7a7a7a;
  border-color: #7a7a7a;
}

.wheel {
  justify-content: center;
}

.result-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  border: 1px solid;

  border-color: @primary;
  background-color: white;
  box-sizing: border-box;
  border-radius: 0 0 0 0;
  transform: rotateX(0deg);
  transition: all 1s ease, border-color 0.2s, background-color 0.2s;
  color: white;
  &.disabled {
    transform: rotateX(360deg);
    border-radius: 0 0 100% 100%;
  }
  &.color-0 {
    border-color: @color0;
    background-color: @color0;
  }
  &.color-1 {
    border-color: @color1;
    background-color: @color1;
  }
  &.color-2 {
    border-color: @color2;
    background-color: @color2;
  }
  &.color-3 {
    border-color: @color3;
    background-color: @color3;
  }
  &.color-4 {
    border-color: @color4;
    background-color: @color4;
  }
  &.color-5 {
    border-color: @color5;
    background-color: @color5;
  }
  &.color-6 {
    border-color: @color6;
    background-color: @color6;
  }
  &.color-7 {
    border-color: @color7;
    background-color: @color7;
  }
  &.color-8 {
    border-color: @color8;
    background-color: @color8;
  }
  &.color-9 {
    border-color: @color9;
    background-color: @color9;
  }
}
