@import "styles/constants.less";

.page {
  //   overflow-x: clip;
  // overflow: hidden;
  //   max-height: 100vh;
  //   overflow: hidden;
  &.shadow-top {
    box-shadow: 0px -30px 135px 150px @black;
  }
}

.image-bg {
  background: bottom;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 150%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}
.layer {
  background: linear-gradient(0deg, transparent 0%, @black 65%);
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.horizontal-pages-container {
  overflow-x: clip;
  // overflow: hidden;
}
.horizontal-page {
  .child(@n, @i: 1) when (@i =< 10) {
    &:nth-child(@{i}):not(:first-child) {
      transform: translateX(100vw * (@i - 1)) translateY(100vh * -(@i - 1));
    }
    .child(@n, (@i + 1));
  }
  .child(10);
}
