@import "styles/constants.less";

.buble {
  width: 100px;
  height: 100px;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  background: radial-gradient(
    circle at top,
    rgba(255, 255, 255, 0.07),
    rgb(255 255 255 / 15%)
  );
  border-radius: 50%;
  box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.17),
    5px 5px 11px rgb(0 0 0 / 21%);
  opacity: 0.9;
  position: absolute;
  overflow: hidden;
}
