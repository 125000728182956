@import "styles/constants.less";

#main-menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 0;
  width: 0px;
  height: 100%;
  z-index: 1;
  &.open {
    transition: all 0.4s ease-out;
    width: 300px;
    overflow: hidden;
  }
  &.transitioning {
    transition: all 0.4s ease-out;
    background-color: @black;
    width: 100vw;
    animation: transition 0.4s ease-in;
    animation-delay: 0.4s;
    .menu-container {
      justify-content: center;
    }
    .icon-container,
    .menu-container .links-list {
      display: none;
    }
  }
}

@keyframes transition {
  from {
    opacity: 1;
  }
  to {
    .glassmorphism-property;
    opacity: 0;
  }
}

.icon-container {
  display: flex;
  margin: 10px;
  transition: all;
  &:has(.open) {
    justify-content: end;
  }
}
.menu-icon {
  transition: all 0.4 ease-out;
  // &.open {
  //   transform: rotateZ(180deg);
  // }
}
.links-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  button {
    color: white;
    &:hover {
      color: white;
      text-decoration: underline;
    }
  }
}
.menu-container {
  flex-grow: 1;
  padding: 4em;
  display: flex;
  flex-direction: column;
  .logo {
    width: 50px;
    align-self: center;
    margin: 15px;
  }
}

a {
  color: white;
  text-decoration: none;
  font-size: 30px;
  line-height: 50px;
  &:hover {
    text-decoration: underline;
  }
}
