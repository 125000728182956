@import "styles/constants.less";
@import "styles/html.less";
body {
  margin: 0;
  color: @black;
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "beckett";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/beckett/BECKETT_.TTF") format("ttf");
}

html {
  scroll-behavior: smooth;
}

#meytvassa-history > * {
  font-family: "Marcellus SC", serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
header,
footer {
  position: fixed;
  width: 100%;
  padding: 1em;
  display: flex;

  justify-content: center;
  box-sizing: border-box;
  img {
    height: 2em;
  }
}
footer {
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  .copyright {
    color: white;
    font-size: 10px;
  }
}
.main-container {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-color: @black;
}

.page {
  width: 100vw;
  height: 100vh;
  position: relative;
}

h1 {
  color: @secondary;
}
h2 {
  color: @blue;
}
h3 {
  color: @secondary;
}
.flex-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.secondary {
  color: @secondary;
}
.error {
  color: @red;
}
.success {
  color: @purple;
}
.texting {
  color: @yellow;
}
.flex-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  // text-align: center;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}
.ghost-input {
  background-color: transparent !important;
  border: transparent !important;
  color: transparent !important;
  border: transparent !important;
  outline: none !important;
  box-shadow: none !important;
}

.glassmorphism {
  .glassmorphism-property;
}
.glass-input {
  &:extend(.glassmorphism);
  input {
    color: white;
    background: transparent;
  }
  ::placeholder {
    color: white !important;
  }
  &.hide {
    filter: opacity(0);
    width: 0px;
  }
  &.show {
    filter: opacity(1);
    max-width: 80vw;
  }
}
.green {
  color: @green;
}
.primary {
  color: @primary;
}

.bg-purple {
  background: @purple;
}
.bg-black {
  background: @black;
}

@media screen and (max-width: 700px) {
  .flex-row,
  .flex-direction {
    flex-direction: column;
  }
  .flex-wrap {
    flex-direction: column;
  }
}
