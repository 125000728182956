@import "styles/constants.less";

.choice-button {
  position: relative;
  overflow: hidden;
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 3em;
  cursor: pointer;
  transition: all 0.5s;
  .image-bg:not(.fixed) {
    transition: all 0.5s;
    filter: blur(3px);
  }
  .opacity-layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      0deg,
      @black 0%,
      transparent 50%,
      transparent 50%,
      @black 100%
    );
    // opacity: 0.6;
    transition: all 0.5s;
  }
  &:hover:not(.fixed) {
    .opacity-layer {
      opacity: 0.5;
    }
    .image-bg {
      transform: scale(1.1);
      filter: blur(0px);
    }
    .text-button::after {
      width: 100%;
    }
  }
  .text-button {
    z-index: 1;
    position: relative;
    background-color: transparent;
    border: none;
    font-family: "Marcellus SC", serif;
    color: white;
    width: max-content;
    max-width: 80%;
    display: flex;
    flex-wrap: wrap;

    .default-font-size;
    &::after {
      content: "";
      position: absolute;
      bottom: -1em;
      left: 0;
      background: white;
      height: 2px;
      width: 0%;
      transition: all 1s;
    }
  }
}

.button-ornament {
  z-index: 1;
  width: 100px;
}
