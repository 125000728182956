@import "styles/constants.less";
.board {
  width: 400px;
  height: 400px;
}
.box {
  width: 90px;
  height: 90px;
  border: 1px solid @black;

  border-radius: 3px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  gap: 1px;
  &.overed {
    opacity: 0.2;
    &.unauthorized {
      opacity: 1;
      cursor: not-allowed !important;
    }
  }

  &:has(.red-accept) {
    border-color: @red;
  }
  &:has(.blue-accept) {
    border-color: @blue;
  }
  &:has(.green-accept) {
    border-color: @green;
  }
  &:has(.yellow-accept) {
    border-color: @yellow;
  }
  &:has(.purple-accept) {
    border-color: @purple;
  }
  &:has(.orange-accept) {
    border-color: @orange;
  }

  &:has(.item) {
    &:has(.red) {
      background: @red;
    }
    &:has(.blue) {
      background: @blue;
    }
    &:has(.green) {
      background: @green;
    }
    &:has(.yellow) {
      background: @yellow;
    }
    &:has(.purple) {
      background: @purple;
    }
    &:has(.orange) {
      background: @orange;
    }
    // RED
    &:has(.red + .blue) {
      background: mix(@blue, @red, 50%);
      &:has(.yellow) {
        background: mix(@yellow, mix(@red, @blue, 50%), 50%);
        &:has(.green) {
          background: mix(
            @green,
            mix(@yellow, mix(@red, @blue, 50%), 50%),
            50%
          );
        }
      }
      &:has(.green) {
        background: mix(@green, mix(@red, @blue, 50%), 50%);
      }
    }

    &:has(.red + .yellow) {
      background: mix(@yellow, @red, 50%);
      &:has(.green) {
        background: mix(@yellow, mix(@red, @green, 50%), 50%);
      }
    }

    &:has(.red + .green) {
      background: mix(@red, @green, 50%);
    }
    // BLUE

    &:has(.blue.yellow) {
      background: mix(@yellow, @blue, 50%);
    }
    &:has(.blue + .green) {
      background: mix(@blue, @green, 50%);
    }
    // YELLOW
    &:has(.yellow + .green) {
      background: mix(@yellow, @green, 50%);
    }
  }
}
.position {
  position: absolute;
  bottom: 0;
  right: 0;
}
.item {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid black;
  cursor: grab;
  &.red {
    background: @red;
  }
  &.green {
    background: @green;
  }
  &.blue {
    background: @blue;
  }
  &.yellow {
    background: @yellow;
  }
  &.purple {
    background: @purple;
  }
  &.orange {
    background: @orange;
  }
}
.accepts {
  position: absolute;
  display: flex;
  border: 1px solid @black;
  top: 2px;
  left: 2px;
  span {
    width: 10px;
    height: 10px;
  }
  .red-accept {
    background: @red;
    border-color: @red;
  }
  .blue-accept {
    background: @blue;
    border-color: @blue;
  }
  .green-accept {
    background: @green;
    border-color: @green;
  }
  .yellow-accept {
    background: @yellow;
    border-color: @yellow;
  }
  .purple-accept {
    background: @purple;
    border-color: @purple;
  }
  .orange-accept {
    background: @orange;
    border-color: @orange;
  }
}
